import {Controller} from 'stimulus'
import Dropkick from 'dropkickjs'

export default class extends Controller {
  static targets = ['select', 'col']

  connect() {
    new Dropkick(this.selectTarget, {
      mobile: true,
      initialize() {
        document.querySelectorAll('.menu__col').forEach((el) => {
          el.classList.add('disabled')
        })
        document.querySelector(`[data-category*='${this.value}']`).classList.remove('disabled')
      },
      change() {
        document.querySelectorAll('.menu__col').forEach((el) => {
          el.classList.add('disabled')
        })
        document.querySelector(`[data-category*='${this.value}']`).classList.remove('disabled')
      }
    })
  }
}
